<template>
  <div
    v-if="form"
    class="notion-complete-form"
    :class="{ 'pt-4': isEmbedPopup }"
    :style="{ '--font-family': form.font_family }"
  >
    <link
      v-if="adminPreview && form.font_family"
      rel="stylesheet"
      :href="getFontUrl"
    >
    <h1
      v-if="!isHideTitle"
      class="mb-4 px-2"
      v-text="form.title"
    />

    <div
      v-if="isPublicFormPage && form.is_protected && form.restricted_to_workspace_users"
      class="text-center"
    >
      <div
        class="p-2 my-4 flex flex-col items-center rounded-md bg-neutral-100 dark:bg-neutral-800 dark:text-neutral-800 border"
      >
        <template v-if="isIframe">
          <p class="mb-0 py-2 px-4 text-gray-800 dark:text-gray-200">
            This content cannot be accessed here. Click the link below to access it.
          </p>
          <v-button
            class="my-4"
            :href="form.share_url"
            target="_blank"
          >
            Open Form
            <Icon
              name="heroicons:arrow-top-right-on-square"
              size="16"
              class="mb-1"
            />
          </v-button>
        </template>
        <template v-else>
          <div class="flex items-center gap-1 p-2 my-2 border rounded-md bg-white">
            <workspace-icon
              class="inline"
              :workspace="form.workspace"
            />
            <span class="font-semibold pr-1">{{ form.workspace.name }}</span>
          </div>
          <p class="mb-0 py-2 px-4 text-gray-800 dark:text-gray-200">
            Restricted to members of the Notion Workspace. Login to access this content.
          </p>
          <login-with-notion
            class="my-4"
            text="Login with Notion"
            :guest-workspace-user="true"
            @success="onNotionConnectSuccess"
          />
        </template>
      </div>
    </div>

    <div v-else-if="isPublicFormPage && form.is_protected && form.has_password">
      <p class="form-description mb-4 text-gray-700 dark:text-gray-300 px-2">
        This form is protected by a password.
      </p>
      <div class="form-group flex flex-wrap w-full">
        <div class="relative mb-3 w-full px-2">
          <text-input
            :theme="theme"
            :form="passwordForm"
            name="password"
            native-type="password"
            label="Password"
          />
        </div>
      </div>
      <div class="flex flex-wrap justify-center w-full text-center">
        <notion-form-button
          :theme="theme"
          :color="form.color"
          @click="passwordEntered"
        >
          Submit
        </notion-form-button>
      </div>
    </div>

    <VTransition name="fade">
      <div
        v-if="authenticated && !form.is_protected && !hidePasswordDisabledMsg && (form.password || form.restricted_to_workspace_users)"
        class="m-2 my-4 flex flex-grow items-end p-4 rounded-md dark:text-yellow-500 bg-yellow-50 dark:bg-yellow-600/20 dark:border-yellow-500"
      >
        <p class="mb-0 text-yellow-600 dark:text-yellow-600 text-sm">
          <template v-if="form.restricted_to_workspace_users && form.password">
            We disabled the Notion and Password protection for this form because you're its owner.
          </template>

          <template v-else-if="form.restricted_to_workspace_users">
            We disabled the Notion protection for this form because you're its owner.
          </template>

          <template v-else>
            We disabled the password protection for this form because you're its owner.
          </template>
        </p>
        <UButton
          color="yellow"
          size="xs"
          @click="hidePasswordDisabledMsg = true"
        >
          Close
        </ubutton>
      </div>
    </VTransition>

    <ClientOnly>
      <div
        v-if="isPublicFormPage && (form.is_closed || form.visibility == 'closed')"
        class="border shadow-sm p-2 my-4 flex items-center rounded-md bg-yellow-100 dark:text-yellow-600 border-yellow-500 dark:bg-yellow-600/20 dark:border-yellow-500"
      >
        <div
          class="flex-grow mb-0 py-2 px-4 text-yellow-600 dark:text-yellow-600"
          v-html="form.closed_text"
        />
      </div>

      <div
        v-if="isPublicFormPage && form.max_number_of_submissions_reached"
        class="border shadow-sm p-2 my-4 flex items-center rounded-md bg-yellow-100 border-yellow-500 dark:text-yellow-600 dark:bg-yellow-600/20 dark:border-yellow-500"
      >
        <div
          class="flex-grow mb-0 py-2 px-4 text-yellow-600 dark:text-yellow-600"
          v-html="form.max_submissions_reached_text"
        />
      </div>
    </ClientOnly>

    <form-cleanings
      v-if="!adminPreview && showCleanings"
      :hideable="true"
      class="mb-4 mx-2"
      :form="form"
      :specify-form-owner="true"
    />


    <VTransition name="fade">
      <div
        v-if="!submitted"
        key="form"
      >
        <!--    Record Selection    -->
        <div
          v-if="form && form.respondent_form_selection && form.respondent_form_selection.enabled && isRecordSelectionDisplay"
          class="mx-2"
        >
          <div
            v-if="form.respondent_form_selection.introduction_text"
            class="form-description mb-4 text-gray-700 dark:text-gray-300 whitespace-pre-wrap"
            v-html="form.respondent_form_selection.introduction_text"
          />
          <relation-input
            name="select_record_id"
            class="mt-4"
            :form-slug="form.slug"
            :multiple="false"
            :theme="theme"
            :use-form-records-endpoint="isPublicFormPage"
            :use-private-endpoint="!isPublicFormPage"
            :workspace-id="form.workspace_id"
            :show-icon="!form.respondent_form_selection.hide_relation_icon"
            :displayed-property-id="form.respondent_form_selection.displayed_property_id"
            :displayed-property-type="form.respondent_form_selection.displayed_property_type"
            :filter="form.respondent_form_selection.filter"
            :relation="{ 'database_id': form.database_id }"
            :property-id="form.database_id"
            :label="form.respondent_form_selection.relation_input_label"
            @update:model-value="onSelectRecord"
          />

          <template v-if="form.respondent_form_selection.allow_creation">
            <p class="my-2 text-gray-500 text-xs text-center">
              OR
            </p>
            <notion-form-button
              :color="form.color"
              class="w-full btn-create-new"
              @click.prevent="isRecordSelectionDisplay = false"
            >
              {{ form.respondent_form_selection.create_new_button_text }}
            </notion-form-button>
          </template>
        </div>

        <template
          v-else-if="form"
        >
          <notion-form
            :key="formRenderKey"
            :form="form"
            :loading="loading"
            :fields="form.properties"
            :theme="theme"
            :dark-mode="darkMode"
            :admin-preview="adminPreview"
            @submit="onFormSubmitted"
          >
            <template #submit-btn="{ submitForm }">
              <notion-form-button
                v-if="!isEditablePage && (adminPreview || form.is_enterprise) && form.respondent_form_selection && form.respondent_form_selection.enabled"
                :loading="loading"
                :theme="theme"
                :color="form.color"
                class="mt-2 px-8 mx-1 btn-prev"
                @click.prevent="onRecordPrevious"
              >
                {{ form.respondent_form_selection.previous_button_text }}
              </notion-form-button>
              <notion-form-button
                :loading="loading"
                :theme="theme"
                :color="form.color"
                class="mt-2 px-8 mx-1 btn-submit"
                :class="submitButtonClass"
                @click.prevent="submitForm"
              >
                {{ form.submit_button_text }}
              </notion-form-button>
            </template>
          </notion-form>
        </template>
        <p
          v-if="!form.no_branding"
          class="text-center w-full mt-2"
        >
          <a
            href="https://noteforms.com?utm_source=form&utm_content=powered_by"
            class="text-gray-400 hover:text-gray-500 dark:text-gray-600 dark:hover:text-gray-500 cursor-pointer hover:underline text-xs"
            target="_blank"
          >Powered by NoteForms</a>
        </p>
      </div>
      <div
        v-else
        key="submitted"
        class="px-2"
      >
        <div
          class="form-description text-gray-700 dark:text-gray-300 whitespace-pre-wrap"
          v-html="form.submitted_text"
        />
        <div class="flex flex-wrap">
          <notion-form-button
            v-if="form.re_fillable"
            :theme="theme"
            :color="form.color"
            class="my-4 mr-4 btn-refill"
            @click="restart"
          >
            {{ form.re_fill_button_text }}
          </notion-form-button>
          <p
            v-if="form.is_enterprise && form.editable_submissions && createdPageId"
            class="my-4"
          >
            <a
              target="_parent"
              :href="form.share_url + '?page_id=' + createdPageId"
            >
              <v-button>
                {{ form.editable_submissions_button_text }}
              </v-button>
            </a>
          </p>
        </div>
        <p
          v-if="!form.no_branding"
          class="mt-5"
        >
          Now <span class="font-semibold">create your own form</span>, it's free and easy!
        </p>
        <powered-by
          v-if="!form.no_branding"
          class="block mt-3 w-max"
        />
      </div>
    </vtransition>
  </div>
</template>

<script>
import { computed } from 'vue'
import NotionForm from './NotionForm.vue'
import NotionFormButton from './NotionFormButton.vue'
import VTransition from '~/components/global/transitions/VTransition.vue'
import { pendingSubmission } from '~/composables/forms/pendingSubmission.js'
import FormCleanings from '../../pages/forms/FormCleanings.vue'
import RelationInput from '../../forms/notiontools/RelationInput.vue'
import LoginWithNotion from '~/components/global/LoginWithNotion.vue'
import WorkspaceIcon from '../workspaces/WorkspaceIcon.vue'
import clonedeep from 'clone-deep'
import ThemeBuilder from "~/lib/forms/themes/ThemeBuilder.js"

export default {
  components: {
    WorkspaceIcon,
    FormCleanings,
    RelationInput,
    VTransition,
    NotionForm,
    NotionFormButton,
    LoginWithNotion
  },

  props: {
    form: { type: Object, required: true },
    creating: { type: Boolean, default: false }, // If true, fake form submit
    adminPreview: { type: Boolean, default: false }, // If used in FormEditorPreview
    showCleanings: { type: Boolean, default: true }, // If used in FormEditorPreview
    submitButtonClass: { type: String, default: '' },
    darkMode: {
      type: Boolean,
      default: false
    }
  },

  emits: ['guest-workspace-user-logined', 'password-entered', 'restarted', 'submitted'],

  setup (props) {
    const authStore = useAuthStore()
    return {
      authenticated: computed(() => authStore.check),
      confetti: useConfetti(),
      isIframe: useIsIframe(),
      pendingSubmission: pendingSubmission(props.form)
    }
  },

  data () {
    return {
      loading: false,
      submitted: false,
      passwordForm: useForm({
        password: null
      }),
      hidePasswordDisabledMsg: false,
      createdPageId: false,
      formRenderKey: 0,
      isRecordSelectionDisplay: false
    }
  },

  computed: {
    isEmbedPopup () {
      return import.meta.client && window.location.href.includes('popup=true')
    },
    isEditablePage () {
      return import.meta.client && window.location.href.includes('page_id=')
    },
    theme () {
      return new ThemeBuilder(this.form.theme, {
        size: this.form.size,
        borderRadius: this.form.border_radius
      }).getAllComponents()
    },
    isPublicFormPage () {
      return this.$route.name === 'forms-slug'
    },
    isHideTitle () {
      return this.form.hide_title || (import.meta.client && window.location.href.includes('hide_title=true'))
    },
    getFontUrl() {
      if(!this.form || !this.form.font_family) return null
      const family = this.form?.font_family.replace(/ /g, '+')
      return `https://fonts.googleapis.com/css?family=${family}:wght@400,500,700,800,900&display=swap`
    }
  },

  mounted () {
    if (!this.isEditablePage && this.form && !this.form.without_database && (this.adminPreview || this.form.is_enterprise) && this.form.respondent_form_selection && this.form.respondent_form_selection.enabled) {
      this.isRecordSelectionDisplay = true
    }
  },

  methods: {
    onFormSubmitted (form, onFailure) {
      if (this.creating) {
        this.submitted = true
        this.$emit('submitted', true)
        return
      }

      this.loading = true
      form.post('/forms/' + this.form.slug + '/answer').then((data) => {
        useAmplitude().logEvent('form_submission', {
          workspace_id: this.form.workspace_id,
          form_id: this.form.id,
          from_iframe: this.isIframe,
          domain: window.location.hostname
        })

        const payload = clonedeep({
          type: 'form-submitted',
          form: {
            slug: this.form.slug,
            id: this.form.id,
            redirect_target_url: (this.form.is_pro && data.redirect && data.redirect_url) ? data.redirect_url : null
          },
          submission_data: form.data()
        })

        if (this.isIframe) {
          window.parent.postMessage(payload, '*')
        }
        window.postMessage(payload, '*')

        try {
          this.pendingSubmission.remove()
        } catch (e) {
          console.error(e)
        }

        if (data.redirect && data.redirect_url) {
          window.location.href = data.redirect_url
        }

        if (data.created_page_id) {
          this.createdPageId = data.created_page_id
        }

        // If enabled display confetti
        if (this.form.confetti_on_submission) {
          this.confetti.play()
        }

        this.loading = false
        this.$emit('submitted', true)
        if (this.form.re_fillable && this.form.bypass_success_page) {
          // Reset form
          useAlert().success(this.form.submitted_text, 10000, { data: { dangerouslySetInnerHtml: true } })
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.formRenderKey++
          form.reset()
        } else {
          this.submitted = true
        }
      }).catch((error) => {
        console.error(error)
        if (error && error.data && error.data.message) {
          useAlert().error(error.data.message)
        } else {
          useAlert().error('Something went wrong, please try again.')
        }
        this.loading = false
        onFailure()
      })
    },
    restart () {
      this.submitted = false
      if (this.form && this.form.is_enterprise && this.form.respondent_form_selection && this.form.respondent_form_selection.enabled) {
        this.isRecordSelectionDisplay = true
      }
      this.$emit('restarted', true)
    },
    passwordEntered () {
      if (this.passwordForm.password !== '' && this.passwordForm.password !== null) {
        this.$emit('password-entered', this.passwordForm.password)
      } else {
        this.addPasswordError('The Password field is required.')
      }
    },
    addPasswordError (msg) {
      this.passwordForm.errors.set('password', msg)
    },
    onSelectRecord (val) {
      if (val) {
        this.form.notion_page_id = val
        this.isRecordSelectionDisplay = false
      }
    },
    onRecordPrevious () {
      this.form.notion_page_id = null
      this.isRecordSelectionDisplay = true
      this.pendingSubmission.remove()
    },
    async onNotionConnectSuccess (cookieValue) {
      const cookie = useCookie('guest-ws-' + this.form.slug, {
        maxAge: 60 * 60 * 7,
        sameSite: 'none',
        secure: true
      })
      cookie.value = cookieValue
      nextTick(() => {
        this.$emit('guest-workspace-user-logined')
      })
    }
  }
}
</script>

<style lang='scss'>
.notion-complete-form {
  * {
    font-family: var(--font-family) !important;
  }

  .form-description,
  .nf-text {
    ol {
      @apply list-decimal list-inside;
      margin-left: 10px;
    }

    ul {
      @apply list-disc list-inside;
      margin-left: 10px;
    }

    p[style*="color:rgb(0,0,0)"],
    span[style*="color:rgb(0,0,0)"] {
      @apply text-gray-700 dark:text-gray-300 #{!important};
    }
  }
}
</style>
